<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              <span>单据编号：</span>
              <el-input
                v-model="parameter.expenseNumber"
                @change="dataUpdate"
                class="ipt_width expense__width"
                placeholder="请输入单据编号"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span>发生日期：</span>
              <el-date-picker
                class="date_width"
                v-model="occurCreatedDate"
                :pickerOptions="occurPickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="发生日期（始）"
                end-placeholder="发生日期（末）"
              >
              </el-date-picker>
            </div>

            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="drawer = true">登记流程</el-button>
          <el-button type="success" style="padding: 0">
            <a
              style="display: block; height: 40px; width: 100px; line-height: 40px"
              target="_blank"
              href="https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/支出登记管理操作手册.pdf"
              >操作文档</a
            ></el-button
          >
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.approvalType" class="fullScreenMainHeader">
            <el-tab-pane name="NOT_APPLY_CONFIRM" label="未确认"></el-tab-pane>
            <el-tab-pane name="ALREADY_APPLY_CONFIRM" label="已确认"></el-tab-pane>
            <el-tab-pane name="APPROVAL_REJECT" label="不通过"></el-tab-pane>
          </el-tabs>

          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :key="Math.random()"
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              min-width="160"
              prop="expenseNumber"
              label="单据编号"
            ></el-table-column>
            <el-table-column
              align="center"
              sortable
              min-width="100"
              prop="createName"
              label="创建人"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="registrationType"
              min-width="110"
              sortable
              label="登记类型"
            >
              <template slot-scope="scope">
                {{ scope.row.registrationType | dict(registrationTypeList) }}
              </template></el-table-column
            >
            <template v-if="parameter.approvalType != 'NOT_APPLY_CONFIRM'">
              <el-table-column
                align="center"
                sortable
                min-width="110"
                prop="expenseType"
                label="费用类型"
              >
                <template slot-scope="scope">
                  {{ scope.row.expenseType | dict(expenseTypeList) }}
                </template></el-table-column
              >
              <!-- <el-table-column
                align="center"
                sortable
                min-width="200"
                prop="disbursementType"
                label="支出类型"
              >
                <template slot-scope="scope">
                  {{ scope.row.disbursementType | dict(dictData.disbursementType) }}
                </template></el-table-column
              > -->
              <el-table-column
                align="center"
                :show-overflow-tooltip="false"
                sortable
                min-width="280"
                prop="expenseBelongName"
                label="费用所属"
              >
              </el-table-column>
              <el-table-column
                align="center"
                :show-overflow-tooltip="false"
                sortable
                min-width="110"
                prop="companyType"
                label="申请单位"
              >
                <template slot-scope="scope">
                  {{ scope.row.companyType | dict(companyTypeList) }}
                </template></el-table-column
              >
            </template>
            <el-table-column
              align="center"
              min-width="110"
              sortable
              prop="sumCost"
              label="申请金额"
            >
              <template slot-scope="scope">
                {{ scope.row.sumCost | applyAmount }}
              </template></el-table-column
            >
            <!-- <el-table-column
              align="center"
              min-width="110"
              sortable
              prop="taskDate"
              label="申请时间">
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column> -->

            <el-table-column
              align="center"
              min-width="110"
              sortable
              prop="occurDate"
              label="发生日期"
            >
              <template slot-scope="scope">
                {{ scope.row.occurDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              sortable
              min-width="150"
              prop="statusName"
              label="流程进度"
            >
            </el-table-column>

            <el-table-column prop="title" align="center" fixed="right" width="100" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  class="text_Details_Bgc"
                  @click="detail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog width="75%" title="库管登记流程图" :visible.sync="drawer">
      <div style="padding: 5px">
        <img src="@/assets/库管支出登记流程图.png" alt="库管登记流程图" width="100%" />
      </div>
    </el-dialog>
    <Edit :isShow.sync="showEdit" :getTitle="getTitle" :options="form"></Edit>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    Edit: () => import('../Edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  data() {
    return {
      parameter: {
        expenseNumber: null,
        pageNow: 1,
        pageSize: 50,
        total: 0,
        approvalType: 'NOT_APPLY_CONFIRM',
        registrationType: 'INVENTORY_KEEPER', //库管
      },
      pageSize: 0,
      tableData: [],
      loading: false,
      showEditDialog: false,
      showEdit: false,
      form: {},
      drawer: false,
      occurCreatedDate: [],
      getTitle: '',
      companyTypeList: [],
      expenseTypeList: [],
      registrationTypeList: [],
      occurPickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.occurCreatedDate, 0, v.minDate.getTime())
            this.$set(this.occurCreatedDate, 1, '')
          }
        },
      },
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getType()
  },
  watch: {
    'parameter.approvalType': {
      immediate: true, //初始化立即执行
      handler: function (newVal, oldVal) {
        this.parameter.pageNow = 1
        this.getData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
    occurCreatedDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.occurCreatedDate = []
        }
      },
    },
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  methods: {
    getType() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('EXPENSE_TYPE', true)
        .then(res => {
          this.expenseTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('REGISTRATION_TYPE', true)
        .then(res => {
          this.registrationTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getData() {
      this.loading = true
      if (this.occurCreatedDate && this.occurCreatedDate != []) {
        this.parameter.occurStartDate = new Date(this.occurCreatedDate[0]).getTime()
        this.parameter.occurEndDate = new Date(this.occurCreatedDate[1]).getTime()
      } else if (this.occurCreatedDate == null) {
        this.parameter.occurStartDate = null
        this.parameter.occurEndDate = null
      }
      this.$api.register
        .searchTask(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == []) {
            this.tableData = res.data
          } else {
            this.tableData = res.data ? (res.data.records ? res.data.records : []) : []
            this.parameter.total = res.data ? (res.data.total ? res.data.total : 0) : 0
            this.tableData.forEach(item => {
              if (item.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT') {
                item.expenseBelongName = item.businessContent
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    dataUpdate() {
      this.$forceUpdate()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    detail(row) {
      this.getTitle = '支付登记单详情'
      this.form = {
        list_s: 3,
        ...row,
      }
      this.form.passText = ''
      if (this.parameter.approvalType == 'NOT_APPLY_CONFIRM') {
        this.form.passText = '发放'
      }
      this.showEdit = true
    },
    re() {
      this.form = {}
      this.getData()
      this.showEdit = false
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.expenseNumber = null
      this.parameter.occurStartDate = null
      this.parameter.occurEndDate = null
      this.occurCreatedDate = []
      this.parameter.pageNow = 1
      this.getData() /** 加载  数据 **/
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
